import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/paratriennale/paratriennale/loveletter/src/templates/works-page-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const BpHr = makeShortcode("BpHr");
const FixedRadio = makeShortcode("FixedRadio");
const Caption = makeShortcode("Caption");
const LinkButton = makeShortcode("LinkButton");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`リ・ビンユアン`}</h1>
    <p>{`濁流の滝に向かって、小さい画板を、ときに押し流されながらも懸命に掲げ続ける映像《画板 100 x 40》。意味のない行為でも、轟音の中、水しぶきをあげながら渾身の力で踏ん張る作家の８分 54 秒のパフォーマンスに思わず見入ってしまいます。古い石橋の上を、こちら側からあちらへ、そしてまたこちらへと、側転をしながら移動するパフォーマンス《橋が壊れるまで》は、2012 年から毎年記録され、2020 年の今年まで 9 本の映像になっています。橋は側転で壊れるようには見えませんが、淡々と行為を続ける作家の姿が印象的です。`}</p>
    <div className={`fontLvBodyS`}>
＊ 2021年3月31日をもってリ・ビンユアン作品の公開は終了しました。<br />
＊ リ・ビンユアン作品への応答となる磯子区障害者地域活動ホーム+飯塚聡の映像は引き続き当ウェブサイトでご覧いただけます。
    </div>
    <BpHr type={`dot`} mdxType="BpHr" />
    <FixedRadio mdxType="FixedRadio">
  <iframe width="560" height="315" src="https://www.youtube.com/embed/LD0WQ-BhTCo" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </FixedRadio>
    <Caption before={`作品`} medium={`パフォーマンス映像　シングルチャンネル　8分56秒、2017年`} mdxType="Caption">
  《画板 100 x 40》
    </Caption>
    <BpHr type={`dot`} mdxType="BpHr" />
    <LinkButton to={`/works/kasetsu`} mdxType="LinkButton">応答する人: 華雪</LinkButton>
    <LinkButton to={`/works/isokatsu_satoshi-iitsuka`} mdxType="LinkButton">
  応答する人: 磯子区障害者地域活動ホーム + 飯塚聡
    </LinkButton>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      